.bridge-list-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  border-left: 1px solid rgba(#eee, 0.1);
  transition: all .15s ease-in-out;

  @media screen and (max-width: 640px) {
    width: 100vw;
    height: auto;
    min-height: 65vh;
    max-height: 100vh;
    position: sticky;
    top: 0;
    z-index: 999;
    border-left: none;
    border-top: 1px solid rgba(#eee, 0.1);
  }
}

.reload-interval {
  display: flex;
  padding: 1px;
  width: 100%;
  opacity: 1;
  background-color: rgba(#222, 0.3);
  animation: dismissProgress 30s infinite linear;
}

@keyframes dismissProgress {
  0% {
    width: 100%;
    opacity: 1
  }

  100% {
    width: 0;
    opacity: 0.3;
  }
}

.bridge-list-container.theme-pdx {
  background-color: rgba(27, 166, 164, 1.0);

  & .bridge-list-title {
    background-color: rgba(27, 166, 164, 0.96);
  }
}

.bridge-list-container.theme-light {
  background-color: rgba(240, 235, 230, 1.0);

  & .bridge-list-title {
    background-color: rgba(240, 235, 230, 0.96);
    color: #444;
  }

  & .bridge-subtitle,
  & .bridge-title,
  & .site-footer,
  & .bridge-status-up,
  & .bridge-status-down {
    color: #444;
  }

  & ul.past-events {
    color: #444;

  }

  & ul.scheduled-events {
    color: #444;

  }
}

.bridge-list-container.theme-blazers {
  background-color: rgba(202, 49, 50, 1.0);

  & .bridge-list-title {
    background-color: rgba(202, 49, 50, 0.96);
  }
}

.bridge-list-container.theme-timbers {
  background-color: rgba(15, 64, 28, 1.0);

  & .bridge-list-title {
    background-color: rgba(15, 64, 28, 0.96);
  }
}

.bridge-list-container.theme-thorns {
  background-color: rgba(149, 31, 36, 1.0);

  & .bridge-list-title {
    background-color: rgba(149, 31, 36, 0.96);
  }
}

.bridge-list-container.theme-pdp {
  background-color: rgba(32, 98, 145, 1.0);

  & .bridge-list-title {
    background-color: rgba(32, 98, 145, 0.96);
  }
}

.bridge-list-container.theme-ducks {
  background-color: rgba(33, 116, 84, 1.0);

  & .bridge-list-title {
    background-color: rgba(33, 116, 84, 0.96);
  }
}

.bridge-list-container.theme-beavers {
  background-color: rgba(253, 115, 41, 1.0);

  & .bridge-list-title {
    background-color: rgba(253, 115, 41, 0.96);
  }
}

.site-footer {
  display: block;
  padding: 1em 2em;
  font-style: italic;
  font-size:.9em;
  line-height:1.5em;

  & a {
    color: #aaa;
  }
}

.bridge-list-title {
  padding: 1em 2em;
  font-size: 1em;
  position: sticky;
  text-transform: uppercase;
  color: #eee;
  letter-spacing: 0.3px;
  font-weight: bold;
  z-index: 1;
  top: 0;
  transition: all .15s ease-in-out;
  background-color: rgba(31, 31, 39, 0.96);
}

ul.theme-selector {
  display: flex;
  list-style-type: none;
  padding: 2em 2em 1em;

  & li {
    width: 1.5em;
    height: 1.5em;
    border: 1px solid rgba(40, 40, 40, 1.0);
    transition: all .15s ease-in-out;
    background-size: cover;
    background-position: center center;
    opacity: 0.7;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: .75em;
    }

    @media screen and (max-width: 640px) {
      width: 1em;
      height: 1em;

      &:not(:last-child) {
        margin-right: .5em;
      }
    }

    &:hover {
      border-color: #aaa;
      opacity: 0.9;
    }

    &.is-active {
      border-color: #fff;
      border-radius: 50%;
      opacity: 1.0;
      box-shadow: 0 0 5px 1px rgba(#fff, 0.3);
    }
  }
}

.site-footer-social-logo {
  padding: .4em .75em .3em;
  border:1px solid #555;
  transition: all .15s ease-in-out;
  display: inline-block;
  margin-bottom: 1em;
  text-decoration: none;

  &:hover img {
    opacity: 1.0;
  }

  & img {
    top: 1px;
    position: relative;
    transition: all .15s ease-in-out;
    height: 13px;
    margin: 0;
    padding: 0;
    width: auto;
    opacity: 0.6;
    margin-right: 10px;
  }
}

.site-footer-text {
  display: block;
  opacity: 0.5;
}