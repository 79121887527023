.map {
 width: 50vw;
 height: 100vh;
    @media screen and (max-width: 640px) {
        width:100vw;
        height:35vh;
    }
}

.esri-ui .esri-attribution {
    display: none;
}