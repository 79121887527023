.App {
  background-color: rgba(31, 31, 39, 1.0);
  display: flex;
  flex-direction: row;
  color: white;
}
body {
  margin: 0;
  font-family: "Rubik",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(31, 31, 39, 1.0);
}

code {
  font-family: "Rubik",
    monospace;
}

ul {
  padding:0;
  margin:0;
}
@media screen and (max-width: 640px) {
  .App {
    flex-direction: column;
  }
}